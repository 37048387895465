@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nerko+One&display=swap');

/** XS */
@media screen and (min-width: 100px ){
  body{
    min-width: 320px;
  }
  h1.ant-typography {
    /*color: #05ffce !important;*/
    font-size: 3rem !important;
  }
  h2.ant-typography {
    color: white !important;
    font-size: 2rem !important;
  }
  h3.ant-typography {
    color: white !important;
    font-size: 1rem !important;
  }
  h4.ant-typography {
    color: white !important;
    font-size: 1rem !important;
  }
}

/** SM */
@media screen and (min-width: 576px ){
  h1.ant-typography {
    color: white !important;
    font-size: 5rem !important;
  }
  h2.ant-typography {
    /*color: #05ffce !important;*/
    font-size: 3rem !important;
  }
  h3.ant-typography {
    color: white !important;
    font-size: 1rem !important;
  }
  h4.ant-typography {
    color: white !important;
    font-size: 2rem !important;
  }
}

/** MD */
@media screen and (min-width: 768px ){
  h1.ant-typography {
    color: white !important;
    font-size: 7rem !important;
  }
  h2.ant-typography {
    color: white !important;
    font-size: 3rem !important;
  }
  h3.ant-typography {
    color: white !important;
    font-size: 1rem !important;
  }
  h4.ant-typography {
    color: white !important;
    font-size: 3rem !important;
  }
}

/** LG **/
@media screen and (min-width: 992px ){
  h1.ant-typography {
    color: white !important;
    font-size: 9rem !important;
  }
  h2.ant-typography {
    color: white !important;
    font-size: 3rem !important;
  }
  h3.ant-typography {
    color: white !important;
    font-size: 1.5rem !important;
  }
  h4.ant-typography {
    color: white !important;
    font-size: 3rem !important;
  }
}

/** XL */
@media screen and (min-width: 1200px ){
  h1.ant-typography {
    color: white !important;
    font-size: 12rem !important;
  }
  h2.ant-typography {
    color: white !important;
    font-size: 4rem !important;
  }
  h3.ant-typography {
    color: white !important;
    font-size: 2rem !important;
  }
  h4.ant-typography {
    color: white !important;
    font-size: 4rem !important;
  }
}

h1.ant-typography {
  color: white;
}


body {
  margin: 0;
  background-color: #0b0d13;
}

.App{
  width: 100%;
  height: auto;
  margin-top: 10vh;
  padding: 0;
  background-color: #0b0d13;
  /*font-family: 'Inter Tight',Arial,sans-serif;*/
  /*font-weight: 400;*/
  font-family: "Kanit", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: 1.55;
  color: white;
}



.buttonMenu{
  color: #90929C;
  text-decoration: none;
  position: relative;
}

.buttonMenu:hover{
  color: white !important;
}

.buttonMenu:active{
  color: #323232 !important;
}

.buttonMenu::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 1px;
  background: white;
}

.buttonMenu::after {
  width: 0;
  transition: 0.3s;
}
.buttonMenu:hover:after {
  width: 100%;
}

.logoContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.menu{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  transform: translateY(-150%);
  animation: appearance 1s forwards;
  background-color: #0b0d13;
  z-index: 3;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: auto;
  transition: transform .1s linear;
}

.menu.hide{
  transform: translateY(0);
  animation: hide 1s forwards;
}

@keyframes appearance {
  0%{
    transform: translateY(-150%);
  }

  100%{
    transform: translateY(0);
  }
}

@keyframes hide {
  0%{
    transform: translateY(0);
  }

  100%{
    transform: translateY(-150%);
  }
}

.introduction{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}

.dividingLine{
  margin-top: 5vh;
  width: 0;
  height: 0.2vh;
  background-color: rgba(144, 146, 156, 0.5);
  animation: appearanceLine 3s forwards;
}

@keyframes appearanceLine {
  0% {
    width: 0;
  }

  100% {
    width: 80%;
  }
}

.examplesOfWork{
  display: flex;
  width: 80%;
  height: auto;
  justify-content: space-around;
  flex-direction: row;
}

.exampleOfWork{
  margin: 50px 0 0 0 ;
  width: 15vh;
  height: 15vh;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0 rgba(255,255,255,.1);
}

.exampleOfWorkImage {
  border-radius: 10px;
}

#exampleOfWork1{
  animation: appearanceExample1 1s forwards;
}

@keyframes appearanceExample1 {
  0%{
    opacity: 0;
  }

  40%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

#exampleOfWork2{
  animation: appearanceExample2 2s forwards;
}

@keyframes appearanceExample2 {
  0%{
    opacity: 0;
  }

  40%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

#exampleOfWork3{
  animation: appearanceExample3 3.5s forwards;
}

@keyframes appearanceExample3 {
  0%{
    opacity: 0;
  }

  40%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

#exampleOfWork4{
  animation: appearanceExample4 4.5s forwards;
}

@keyframes appearanceExample4 {
  0%{
    opacity: 0;
  }

  60%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

#exampleOfWork5{
  animation: appearanceExample5 6s forwards;
}

@keyframes appearanceExample5 {
  0%{
    opacity: 0;
  }

  50%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

#exampleOfWork6{
  animation: appearanceExample6 2.5s forwards;
}

@keyframes appearanceExample6 {
  0%{
    opacity: 0;
  }

  50%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

#exampleOfWork7{
  animation: appearanceExample7 4.5s forwards;
}

@keyframes appearanceExample7 {
  0%{
    opacity: 0;
  }

  50%{
    opacity: 0;
  }

  100%{
    opacity: 100%;
  }
}

.nameOfCompany{
  margin: 0;
  padding: 0;
  transform: translateY(-20%);
  animation: appearanceNameOfCompany 2.5s forwards;
}

@keyframes appearanceNameOfCompany {
  0%{
    transform: translateY(-20%);
  }

  100%{
    transform: translateY(0%);
  }
}

.anim.logo{
  padding: 10vh;
  opacity: 0;
  transform: translateY(-10%);
}

.anim.logo.active{
  animation: test 2s forwards;
}

@keyframes test {
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

.description{
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 5vh;
  flex-direction: column;
}

.contentOfDescription{
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 2vh;
}

.showPortfolioBtn{
  border-radius: 0;
  background: white;
  color: black;
  padding: 3vh;
  margin-top: 2vh;
}

.showPortfolioBtn:hover{
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: white !important;
}

.showPortfolioBtn:active{
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: white !important;
}

#pic1_mobile{
  border-radius: 5%;
  opacity: 0;
  transform: translateY(-10%);
  width: 17rem;
  height: 25rem;
}

#pic1_full{
  border-radius: 5%;
  opacity: 0;
  transform: translateY(-10%);
  width: 30rem;
  height: 45rem;
}

#pic2_mobile{
  border-radius: 5%;
  opacity: 0;
  transform: translateY(-10%);
  width: 15rem;
  height: 20rem;
}

#pic2_full{
  border-radius: 5%;
  opacity: 0;
  transform: translateY(-10%);
  width: 25rem;
  height: 35rem;
}

.anim.picture.active{
  animation: appearancePic 2s forwards;
}

@keyframes appearancePic {
  0%{
    opacity: 0;
    transform: translateY(-10%);
  }

  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

.moreInformation{
  width: 100%;
  height: auto;
  margin-top: 15vh;
}

.info{
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
}

.pictureInfo{
  width: 35vh;
  -webkit-transform: scale(0.5, 0.5);
  opacity: 0;
}

.textInfo{
  margin-top: 2vh;
  text-align: center;
  min-width: 75vh;
  max-width: 50vh;
}

.pictureInfo.active{
  animation: pictureInfoAppearence 2s forwards;
}

@keyframes pictureInfoAppearence {
  0%{
    -webkit-transform: scale(0.5, 0.5);
    opacity: 0;
  }

  100%{
    -webkit-transform: scale(1, 1);
    opacity: 1;
  }
}

.footer{
  padding-top: 2rem;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.parallax {
  background-image: url("../public/img/afisha_of_works/IMG_1134.JPG");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contacts{
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dividingLineFooter{
  width: 0;
  height: 0.2vh;
  background-color: rgba(144, 146, 156, 0.5);
}

.dividingLineFooter.active{
  animation: appearanceLineFooter 4s forwards;
}

@keyframes appearanceLineFooter {
  0% {
    width: 0;
  }
  100%{
    width: 100%;
  }
}

.contactsFooter{
  padding: 6vh;
}

.contactsPage{
  width: 100%;
  height: auto;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_number{
  font-size: 2rem;
}

.footer_numberText{
  margin: 0;
  font-size: 1rem;
  color: #a2a3a5;
}

.footer_e-mail{
  font-size: 2rem;
}

.footer_e-mailText{
  margin: 0;
  font-size: 1rem;
  color: #a2a3a5;
}

.portfolio{
  padding: 5vh;
}

.portfolioPicture{
  width: 25vh;
}

.projects{
  text-align: center;
  font-size: 4vh;
  margin: 0;
}

.projectsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.urInfo{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.dividingLineMoreInf{
  margin-top: 10vh;
  margin-bottom: 10vh;
  width: 0;
  height: 0.2vh;
  background-color: rgba(144, 146, 156, 0.5);
}

.dividingLineMoreInf.active{
  animation: dividingLineMoreInf 1s forwards;
}

@keyframes dividingLineMoreInf {
  0%{
    width: 0;
  }

  100%{
    width: 70%;
  }
}

.underMoreInf{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.bidBtnModal{
  border-radius: 2%;
  background: black;
  color: white;
  padding: 3vh;
  margin-top: 2vh;
}

.bidBtnModal:hover{
  background-color: #575656 !important;
  color: white !important;
}

.bidBtnModal:active{
  background-color: rgb(78, 78, 78) !important;
  color: white !important;
}

.thanksPage{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes thanksAppear {
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

.ant-drawer-header {
  background-color: #2c2c2c;
  border-radius: 0;
  color: white;
}

.ant-drawer-close{
  color: white !important;
}

.ant-drawer-body {
  position: relative;
  background-color: #121212;
  color: white;
  height: calc(100vh - 55px);
}

.buttonMenuDrawer{
  font-size: 1.5rem;
  color: white;
  position: relative;
  font-weight: 700;
  text-decoration: none;
}

.buttonMenuDrawer:hover{
  color: white !important;
}

.buttonMenuDrawer:active{
  color: #323232 !important;
}

.buttonMenuDrawer::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -0.5rem;
  display: block;
  width: 100%;
  height: 1px;
  background: white;
}

.buttonMenuDrawer::after {
  width: 0;
  transition: 0.3s;
}
.buttonMenuDrawer:hover:after {
  width: 100%;
}

.parallax {
  background-image: url("../public/img/afisha_of_works/IMG_1134.JPG");
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.react-tel-input .form-control{
  width: 100% !important;
}